import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import {get_constituencies} from '../network';

function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(array[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = 'export.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}


export default function MyComponent(props) {
    let [constituencies, setConstituencies] = useState([]);
    let [currentConstituency, setCurrentConstituency] = useState(null);

    useEffect(() => {
        get_constituencies().then((data) => {
            setConstituencies(data);
        });
    }, []);

    const onClickReset = (event) => {
        setCurrentConstituency(null);
        props.onReset();
    };

    return (
        <Container>
            <Row>
                <Col md={8}>
                    <h2 style={{float:'left'}}>Report: {props.title}</h2>
                </Col>
                {
                    !props.totalBooths &&
                    (
                        <Col md={4}>
                            <h3 style={{color:'red', float:'right'}}>Count: {props.count}</h3>
                        </Col>
                    )
                }
            </Row>
            {
                props.totalBooths &&
                (
                    <Row style={{marginTop: '20px'}}>
                        <Col md={3}>
                            <h3 style={{color:'green', float:'left'}}>Count: {props.count}</h3>
                        </Col>
                        <Col md={3}>
                            <h3 style={{color:'green', float:'left'}}>Total: {props.totalBooths ?? 'NA'}</h3>
                        </Col>
                        <Col md={3}>
                            <h3 style={{color:'green', float:'left'}}>Percent: {(props.count / props.totalBooths * 100).toFixed(2) ?? 'NA'}%</h3>
                        </Col>
                    </Row>
                )
            }
            <Row style={{minHeight: '20px'}}></Row>
            <Row>
                <Col md={2} className='d-none d-sm-block'>
                    <label>Constituency</label>
                </Col>
                <Col md={4} xs={8}>
                    <Combobox disabled={props.loading} data={constituencies} value={currentConstituency} dataKey='number' textField='name' style={{paddingLeft: '20px'}} onChange={(val) => {setCurrentConstituency(val); props.onChangeConstituency(val);}}/>
                </Col>
                <Col md={2} xs={4}>
                    <Button onClick={onClickReset}>Reset</Button>
                </Col>
                {
                    props.exportLink && 
                    (
                        <Col md={2} xs={4}>
                            <a className="btn btn-primary" href={`${props.exportLink}?csv${currentConstituency ? `&constituency=${currentConstituency.number}` : ''}`}>Export</a>
                        </Col>
                    )
                }
                {/* <Col md={4}><button onClick={(event) => downloadCSV(props.data)} >Export</button></Col> */}
            </Row>
            <Row style={{minHeight: '20px'}}></Row>
            <Card>
            <DataTable
                defaultSortFieldId={'booth'}
                striped
                pagination
                paginationServer
                columns={props.columns}
                data={props.data}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
                progressPending={props.loading}
                paginationRowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
                paginationTotalRows={props.count}
                paginationPerPage={25}
                expandableRows
                expandableRowsComponent={props.expandableRowsComponent}
                expandOnRowClicked
                // expandableRowsHideExpander
            />
            </Card>
        </Container>
    );
};