/*
    12. List of booths where BJD's vote share is above 45% in both 2014 & 2019.
*/
import DataTable from '../DataTable';
import { useEffect, useState } from 'react';
import {URL_BJD_VOTESHARE_GT_45_BOTH_YEARS, bjd_voteshare_gt_45_both_years} from '../../network';
import ExpandableRowComponent from '../ExpandableRowComponent';

const columns = [
    {
        name: '#',
        cell: (row, index) => index + 1  //RDT provides index by default
    },
    {
        name: 'Constituency Number',
        selector: row => row.ac.number,
    },
    {
        name: 'Constituency Name',
        selector: row => row.ac.name,
    },
    {
        name: 'Booth',
        selector: row => row.booth,
    },
];
export default function BJDVoteshareGT45BothYears(props) {

    let [items, setItems] = useState([]);
    let [loading, setLoading] = useState(true);
    let [count, setCount] = useState(0);
    let [currentPage, setCurrentPage] = useState(1);
    let [currentRowsPerPage, setCurrentRowsPerPage] = useState(25);
    let [constituencies, setConstituencies] = useState([]);
    let [currentConstituency, setCurrentConstituency] = useState(null);
    
    const onChangePage = (page, totalRows) => {
        if(loading) return;
        setLoading(true);
        bjd_voteshare_gt_45_both_years(currentPage, currentRowsPerPage, currentConstituency?.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setCurrentPage(page);
            setLoading(false);
        });
    }

    const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if(loading) return;
        setLoading(true);
        bjd_voteshare_gt_45_both_years(currentPage, currentRowsPerPage, currentConstituency?.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setLoading(false);
            setCurrentPage(currentPage);
            setCurrentRowsPerPage(currentRowsPerPage);
        });
    };

    const onChangeConstituency = (constituency) => {
        setLoading(true);
        bjd_voteshare_gt_45_both_years(currentPage, currentRowsPerPage, constituency.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setCurrentConstituency(constituency);
            setLoading(false);
        });
    };

    const onReset = () => {
        setLoading(true);
        setCurrentConstituency(null);
        bjd_voteshare_gt_45_both_years(currentPage, currentRowsPerPage, null).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        bjd_voteshare_gt_45_both_years(currentPage, currentRowsPerPage, currentConstituency?.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setLoading(false);
        });
    }, []);
    

    return (
        <DataTable 
            columns={columns} 
            title={'BJD Vote Share > 45% both in 2014 & 2019'}
            data={items}
            constituencies={constituencies}
            count={count}
            loading={loading}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangeConstituency={onChangeConstituency}
            onReset={onReset}
            exportLink={URL_BJD_VOTESHARE_GT_45_BOTH_YEARS}
            expandableRows
            expandableRowsComponent={ExpandableRowComponent}
        />
    );
}