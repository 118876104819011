/*
    4. List of booths where BJD trailed from BJP in 2019.
*/
import DataTable from '../DataTable';
import { useEffect, useState } from 'react';
import {URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2019, bjd_vs_bjp_voteshare_diff_lt_10p_2019} from '../../network';
import ExpandableRowComponent from '../ExpandableRowComponent';

const columns = [
    {
        name: '#',
        cell: (row, index) => index + 1  //RDT provides index by default
    },
    {
        name: 'Constituency Number',
        selector: row => row.ac.number,
    },
    {
        name: 'Constituency Name',
        selector: row => row.ac.name,
    },
    {
        name: 'Booth',
        selector: row => row.booth,
    },
    {
        name: 'BJD Voteshare',
        selector: row => `${row.data[0].bjd_percent}%`,
    },
    {
        name: 'BJP Voteshare',
        selector: row => `${row.data[0].bjp_percent}%`,
    }
];

export default function BJDVsBJPVoteshareDiffLT10P2019(props) {

    let [items, setItems] = useState([]);
    let [loading, setLoading] = useState(true);
    let [count, setCount] = useState(0);
    let [totalBooths, setTotalBooths] = useState(0);
    let [currentPage, setCurrentPage] = useState(1);
    let [currentRowsPerPage, setCurrentRowsPerPage] = useState(25);
    let [constituencies, setConstituencies] = useState([]);
    let [currentConstituency, setCurrentConstituency] = useState(null);
    
    const onChangePage = (page, totalRows) => {
        if(loading) return;
        setLoading(true);
        bjd_vs_bjp_voteshare_diff_lt_10p_2019(currentPage, currentRowsPerPage, currentConstituency?.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setTotalBooths(data.total_booths);
            setCurrentPage(page);
            setLoading(false);
        });
    }

    const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if(loading) return;
        setLoading(true);
        bjd_vs_bjp_voteshare_diff_lt_10p_2019(currentPage, currentRowsPerPage, currentConstituency?.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setTotalBooths(data.total_booths);
            setLoading(false);
            setCurrentPage(currentPage);
            setCurrentRowsPerPage(currentRowsPerPage);
        });
    };

    const onChangeConstituency = (constituency) => {
        setLoading(true);
        bjd_vs_bjp_voteshare_diff_lt_10p_2019(currentPage, currentRowsPerPage, constituency.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setTotalBooths(data.total_booths);
            setCurrentConstituency(constituency);
            setLoading(false);
        });
    };

    const onReset = () => {
        setLoading(true);
        setCurrentConstituency(null);
        bjd_vs_bjp_voteshare_diff_lt_10p_2019(currentPage, currentRowsPerPage, null).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setTotalBooths(data.total_booths);
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        bjd_vs_bjp_voteshare_diff_lt_10p_2019(currentPage, currentRowsPerPage, currentConstituency?.number).then((data) => {
            setItems(data.data);
            let constituency_set = new Set();
            items.forEach((item) => {
                constituency_set.add(item.ac);
            });
            setConstituencies(items);
            setCount(data.count);
            setTotalBooths(data.total_booths);
            setLoading(false);
        });
    }, []);
    

    return (
        <DataTable 
            columns={columns} 
            title={'BJD-BJP Voteshare difference < 10% in 2019'}
            data={items}
            constituencies={constituencies}
            count={count}
            totalBooths={totalBooths}
            loading={loading}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangeConstituency={onChangeConstituency}
            onReset={onReset}
            exportLink={URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2019}
            expandableRows
            expandableRowsComponent={ExpandableRowComponent}
        />
    );
}