import axios from 'axios';


const BASE_URL = "https://booths.nitippl.cultnerds.io/api";

const URL_GET_CONSTITUENCIES = BASE_URL + "/constituency/";
const URL_GET_AC_BOOTHS = BASE_URL + "/booth-counts/";
const URL_BJD_TRAILED_BOTH_YEARS = BASE_URL + "/bjd-trailed-both/";
const URL_BJD_TRAILED_2019 = BASE_URL + "/bjd-trailed-2019/";
const URL_BJD_TRAILED_INC_2019 = BASE_URL + "/bjd-trailed-inc-2019/";
const URL_BJD_TRAILED_BJP_2019 = BASE_URL + "/bjd-trailed-bjp-2019/";
const URL_BJD_LEAD_2014_TRAILED_2019 = BASE_URL + "/bjd-lead-2014-trailed-2019/";
const URL_INC_WON_BOTH_YEARS = BASE_URL + "/inc-won-both-years/";
const URL_BJP_WON_BOTH_YEARS = BASE_URL + "/bjp-won-both-years/";
const URL_BJD_WON_BOTH_YEARS = BASE_URL + "/bjd-won-both-years/";
const URL_BJD_VOTESHARE_LT_35_2014 = BASE_URL + "/bjd-voteshare-lt-35-2014/";
const URL_BJD_VOTESHARE_LT_35_2019 = BASE_URL + "/bjd-voteshare-lt-35-2019/";
const URL_BJD_VOTESHARE_LT_35_BOTH_YEARS = BASE_URL + "/bjd-voteshare-lt-35-both/";
const URL_BJD_VOTESHARE_GT_45_BOTH_YEARS = BASE_URL + "/bjd-voteshare-gt-45-both/";
const URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2019 = BASE_URL + "/bjd-bjp-voteshare-diff-lt-10-2019/";
const URL_BJD_VS_INC_VOTESHARE_DIFF_LT_10P_2019 = BASE_URL + "/bjd-inc-voteshare-diff-lt-10-2019/";
const URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2014 = BASE_URL + "/bjd-bjp-voteshare-diff-lt-10-2014/";
const URL_BJD_VS_INC_VOTESHARE_DIFF_LT_10P_2014 = BASE_URL + "/bjd-inc-voteshare-diff-lt-10-2014/";

async function bjd_trailed_both_years(pageNumber, pageSize, constituency) {
    try {
      const resp = await axios.get(`${URL_BJD_TRAILED_BOTH_YEARS}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
      return resp.data;
    } catch (err) {
        throw err;
    }
}

async function bjd_trailed_2019(pageNumber, pageSize, constituency) {
    try {
      const resp = await axios.get(`${URL_BJD_TRAILED_2019}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
      return resp.data;
    } catch (err) {
        throw err;
    }
}

async function bjd_trailed_inc_2019(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_TRAILED_INC_2019}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_trailed_bjp_2019(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_TRAILED_BJP_2019}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_lead_2014_trailed_2019(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_LEAD_2014_TRAILED_2019}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function inc_won_both_years(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_INC_WON_BOTH_YEARS}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjp_won_both_years(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJP_WON_BOTH_YEARS}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_won_both_years(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_WON_BOTH_YEARS}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_voteshare_gt_45_both_years(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_VOTESHARE_GT_45_BOTH_YEARS}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_voteshare_lt_35_both_years(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_VOTESHARE_LT_35_BOTH_YEARS}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_vs_bjp_voteshare_diff_lt_10p_2019(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2019}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_vs_inc_voteshare_diff_lt_10p_2019(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_VS_INC_VOTESHARE_DIFF_LT_10P_2019}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_vs_bjp_voteshare_diff_lt_10p_2014(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2014}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function bjd_vs_inc_voteshare_diff_lt_10p_2014(pageNumber, pageSize, constituency) {
  try {
    const resp = await axios.get(`${URL_BJD_VS_INC_VOTESHARE_DIFF_LT_10P_2014}?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function get_constituencies() {
  try {
    const resp = await axios.get(`${URL_GET_CONSTITUENCIES}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function get_ac_booths() {
  try {
    const resp = await axios.get(`${URL_GET_AC_BOOTHS}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

export {
  URL_BJD_LEAD_2014_TRAILED_2019,
  URL_BJD_TRAILED_2019,
  URL_BJD_TRAILED_BJP_2019,
  URL_BJD_TRAILED_BOTH_YEARS,
  URL_BJD_TRAILED_INC_2019,
  URL_BJD_VOTESHARE_GT_45_BOTH_YEARS,
  URL_BJD_VOTESHARE_LT_35_2014,
  URL_BJD_VOTESHARE_LT_35_2019,
  URL_BJD_VOTESHARE_LT_35_BOTH_YEARS,
  URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2014,
  URL_BJD_VS_BJP_VOTESHARE_DIFF_LT_10P_2019,
  URL_BJD_VS_INC_VOTESHARE_DIFF_LT_10P_2014,
  URL_BJD_VS_INC_VOTESHARE_DIFF_LT_10P_2019,
  URL_BJD_WON_BOTH_YEARS,
  URL_BJP_WON_BOTH_YEARS,
  URL_GET_CONSTITUENCIES,
  URL_INC_WON_BOTH_YEARS,
  bjd_trailed_both_years,
  bjd_trailed_2019,
  bjd_trailed_inc_2019,
  bjd_trailed_bjp_2019,
  bjd_lead_2014_trailed_2019,
  inc_won_both_years,
  bjp_won_both_years,
  bjd_won_both_years,
  bjd_voteshare_gt_45_both_years,
  bjd_voteshare_lt_35_both_years,
  bjd_vs_bjp_voteshare_diff_lt_10p_2019,
  bjd_vs_inc_voteshare_diff_lt_10p_2019,
  bjd_vs_bjp_voteshare_diff_lt_10p_2014,
  bjd_vs_inc_voteshare_diff_lt_10p_2014,
  get_constituencies,
  get_ac_booths,
};