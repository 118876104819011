import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {get_ac_booths} from '../network';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const columns = [
    {
        name: 'Constituency Number',
        selector: row => row.number,
        sortable: true
    },
    {
        name: 'Constituency Name',
        selector: row => row.name,
        sortable: true
    },
    {
        name: 'Booths in 2014',
        selector: row => row['2014'],
        sortable:true
    },
    {
        name: 'Booths in 2019',
        selector: row => row['2019'],
        sortable: true
    },
];

function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = ['number', 'name', '2014', '2019'];

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = 'BoothCountYearWise.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}


export default function BoothList() {
    let [items, setItems] = useState([]);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        get_ac_booths().then((data) => {
            setItems(data);
            setLoading(false);
        });
    }, []);

    return (
        <Container>
            <Row style={{marginBottom: '30px'}}>
                <Col md={6}>
                    <h4 style={{float:'left'}}>Year-Wise Booth Count by Constituency</h4>
                </Col>
                <Col md={4}>
                    <Button onClick={() => {downloadCSV(items);}} style={{float:'left'}}>Export</Button>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card>
                        <DataTable 
                            columns={columns} 
                            data={items}
                            loading={loading}
                            fixedHeader
                            fixedHeaderScrollHeight={'600px'}
                            striped
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};