import { Container, Row, Col } from "react-bootstrap";
import ReportCard from './ReportCard';

export default function BJDPartyWiseReports(props) {
    return (
        <Container>
            <Row>
                <Col md={8}>
                    <h3 style={{float:'left'}}>Party-wise Reports : Biju Janata Dal (BJD)</h3>
                </Col>
                <hr/>
            </Row>
            <Row>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Trailed in both 2014 & 2019'} target={'/bjd-trailed-both-years'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Trailed in 2019'} target={'/bjd-trailed-2019'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Lead in 2014 but trailed in 2019'} target={'/bjd-lead-2014-trailed-2019'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Won in both 2014 & 2019'} target={'/bjd-won-both-years'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Voteshare < 35% both in 2014 & 2019'} target={'/bjd-voteshare-lt-35-both-years'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Voteshare > 45% both in 2014 & 2019'} target={'/bjd-voteshare-gt-45-both-years'}></ReportCard>
                </Col>
            </Row>
        </Container>
    );
};