import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

function PartyCard(props) {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={props.img} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
          Click here to find reports related to {props.title}
        </Card.Text>
        <Link className="btn btn-primary" to={props.target}>Show Reports</Link>
      </Card.Body>
    </Card>
  );
}

export default PartyCard;