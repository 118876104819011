import { Container, Row, Col } from "react-bootstrap";
import ReportCard from './ReportCard';

export default function BJPPartyWiseReports(props) {
    return (
        <Container>
            <Row>
                <Col md={8}>
                    <h3 style={{float:'left'}}>Party-wise Reports : Bharatiya Janata Party (BJP)</h3>
                </Col>
                <hr/>
            </Row>
            <Row>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'Won in both 2014 & 2019'} target={'/bjp-won-both-years'}></ReportCard>
                </Col>
            </Row>
        </Container>
    );
};