import logo from './logo.svg';
import './App.css';
import DataTable from './components/DataTable';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './components/Homepage';
import Navbar from './components/Navbar';
import BJDTrailedBothYears from './components/reports/BJDTrailedBothYears';
import BJDPartyWiseReports from './components/BJDPartyWiseReports';
import BJPPartyWiseReports from './components/BJPPartyWiseReports';
import INCPartyWiseReports from './components/INCPartyWiseReports';
import BJDTrailed2019 from './components/reports/BJDTrailed2019';
import BJDLeadIn2014TrailedIn2019 from './components/reports/BJDLeadIn2014TrailedIn2019';
import BJDWonInBothYears from './components/reports/BJDWonInBothYears';
import BJPWonInBothYears from './components/reports/BJPWonInBothYears';
import INCWonInBothYears from './components/reports/INCWonInBothYears';
import BJDTrailedFromBJP2019 from './components/reports/BJDTrailedFromBJP2019';
import BJDTrailedFromINC2019 from './components/reports/BJDTrailedFromINC2019';
import BJPComparitiveReports from './components/BJPComparitiveReports';
import INCComparitiveReports from './components/INCComparitiveReports';
import BJDVoteshareGT45BothYears from './components/reports/BJDVoteshareGT45BothYears';
import BJDVoteshareLT35BothYears from './components/reports/BJDVoteshareLT35BothYears';
import BJDVsBJPVoteshareDiffLT10P2019 from './components/reports/BJDVsBJPVoteshareDiffLT10P2019';
import BJDVsINCVoteshareDiffLT10P2019 from './components/reports/BJDVsINCVoteshareDiffLT10P2019';
import BJDVsINCVoteshareDiffLT10P2014 from './components/reports/BJDVsINCVoteshareDiffLT10P2014';
import BJDVsBJPVoteshareDiffLT10P2014 from './components/reports/BJDVsBJPVoteshareDiffLT10P2014';
import BoothList from './components/BoothList';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Navbar></Navbar>
        <div className="content-wrapper">
              <div className='container-fluid py-4 px-4'>
                <Routes>
                  {/* <Route path='*' element={<NotFound />} /> */}
                  {/* Redirect login route to home page*/}
                  <Route path="/" element={<Homepage/>} />
                  <Route path="/party-wise/bjd" element={<BJDPartyWiseReports/>} />
                  <Route path="/party-wise/bjp" element={<BJPPartyWiseReports/>} />
                  <Route path="/party-wise/inc" element={<INCPartyWiseReports/>} />
                  <Route path="/bjd-trailed-both-years" element={<BJDTrailedBothYears/>} />
                  <Route path="/bjd-trailed-2019" element={<BJDTrailed2019/>} />
                  <Route path="/bjd-lead-2014-trailed-2019" element={<BJDLeadIn2014TrailedIn2019/>} />
                  <Route path="/bjd-voteshare-gt-45-both-years" element={<BJDVoteshareGT45BothYears/>} />
                  <Route path="/bjd-won-both-years" element={<BJDWonInBothYears/>} />
                  <Route path="/bjp-won-both-years" element={<BJPWonInBothYears/>} />
                  <Route path="/inc-won-both-years" element={<INCWonInBothYears/>} />
                  <Route path="/bjd-trailed-from-bjp-2019" element={<BJDTrailedFromBJP2019/>} />
                  <Route path="/bjd-trailed-from-inc-2019" element={<BJDTrailedFromINC2019/>} />
                  <Route path="/comparitive/bjp" element={<BJPComparitiveReports />} />
                  <Route path="/comparitive/inc" element={<INCComparitiveReports />} />
                  <Route path="/bjd-voteshare-lt-35-both-years" element={<BJDVoteshareLT35BothYears />} />
                  <Route path="/bjd-bjp-voteshare-diff-lt-10-2019" element={<BJDVsBJPVoteshareDiffLT10P2019 />} />
                  <Route path="/bjd-inc-voteshare-diff-lt-10-2019" element={<BJDVsINCVoteshareDiffLT10P2019 />} />
                  <Route path="/bjd-bjp-voteshare-diff-lt-10-2014" element={<BJDVsBJPVoteshareDiffLT10P2014 />} />
                  <Route path="/bjd-inc-voteshare-diff-lt-10-2014" element={<BJDVsINCVoteshareDiffLT10P2014 />} />
                  <Route path="/booths-ac-year-wise" element={<BoothList />} />
                </Routes>
              </div>
            </div>
            </BrowserRouter>
    </div>
  );
}

export default App;
