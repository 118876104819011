import { Container, Row, Col } from "react-bootstrap";
import PartyCard from "./PartyCard";
import ReportCard from "./ReportCard";

export default function Homepage() {
    return (
        <Container>
            <Row>
                <Col md={3}>
                    <h3 style={{float:'left'}}>Party-wise analysis</h3>
                </Col>
                <hr/>
            </Row>
            <Row>
                <Col md={3} style={{margin: '20px'}}>
                    <PartyCard title={'BJD'} img={process.env.PUBLIC_URL + 'BJD.jpg'} target={'/party-wise/bjd/'}></PartyCard>
                </Col>
                <Col md={3} style={{margin: '20px'}}>
                    <PartyCard title={'BJP'} img={process.env.PUBLIC_URL + 'BJP.jpg'} target={'/party-wise/bjp/'}></PartyCard>
                </Col>
                <Col md={3} style={{margin: '20px'}}>
                    <PartyCard title={'INC'} img={process.env.PUBLIC_URL + 'INC.png'} target={'/party-wise/inc/'}></PartyCard>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={3}>
                    <h3 style={{float:'left'}}>Comparitive analysis</h3>
                </Col>
                <hr/>
            </Row>
            <Row>
                <Col md={3} style={{margin: '20px'}}>
                    <PartyCard title={'BJD vs BJP'} img={process.env.PUBLIC_URL + 'BJD-BJP.png'} target={'/comparitive/bjp'}></PartyCard>
                </Col>
                <Col md={3} style={{margin: '20px'}}>
                    <PartyCard title={'BJD vs INC'} img={process.env.PUBLIC_URL + 'BJD-INC.png'} target={'/comparitive/inc'}></PartyCard>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <h3 style={{float:'left'}}>Statistics</h3>
                </Col>
                <hr/>
            </Row>
            <Row>
                <Col md={3} style={{margin: '20px'}}>
                    <ReportCard title={'Constituency wise booth counts'} target={'/booths-ac-year-wise'}></ReportCard>
                </Col>
            </Row>
        </Container>
    );
};