import { Container, Row, Col } from "react-bootstrap";
import ReportCard from './ReportCard';

export default function INCComparitiveReports(props) {
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 style={{float:'left'}}>Comparitive Reports : Biju Janata Dal (BJD) vs Indian National Congress (INC)</h3>
                </Col>
                <hr/>
            </Row>
            <Row>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'BJD Trailed from INC in 2019'} target={'/bjd-trailed-from-inc-2019'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'BJD vs INC Voteshare difference < 10% in 2014'} target={'/bjd-inc-voteshare-diff-lt-10-2014'}></ReportCard>
                </Col>
                <Col md={3} style={{padding: '20px'}}>
                    <ReportCard title={'BJD vs INC Voteshare difference < 10% in 2019'} target={'/bjd-inc-voteshare-diff-lt-10-2019'}></ReportCard>
                </Col>
            </Row>
        </Container>
    );
};