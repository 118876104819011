import 'bootstrap/dist/css/bootstrap.min.css';  
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap';


export default function Navigation() {
  return (
    <Navbar bg="secondary" expand="md">
      <Container>
        <Navbar.Brand href="/" className='text-white'>
        <img
              alt=""
              src={process.env.PUBLIC_URL + 'NPPL.jpg'}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            NITIPPL</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" className='text-white'>Home</Nav.Link>
            <NavDropdown title="Party-Wise" id="basic-nav-dropdown">
              <NavDropdown.Item href="/party-wise/bjd">Biju Janata Dal</NavDropdown.Item>
              <NavDropdown.Item href="/party-wise/bjp">Bharatiya Janata Party</NavDropdown.Item>
              <NavDropdown.Item href="/party-wise/inc">Indian National Congress</NavDropdown.Item>
              {/* <NavDropdown.Divider />   */}
              {/* <NavDropdown.Item href="#action/3.4">Another Item</NavDropdown.Item>   */}
            </NavDropdown>
            <NavDropdown title="Comparitive" id="basic-nav-dropdown">
              <NavDropdown.Item href="/comparitive/bjp">BJD vs BJP</NavDropdown.Item>
              <NavDropdown.Item href="/comparitive/inc">BJD vs INC</NavDropdown.Item>
              {/* <NavDropdown.Divider />   */}
              {/* <NavDropdown.Item href="#action/3.4">Another Item</NavDropdown.Item>   */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}