import { useEffect } from 'react';
import {Container, Table, } from 'react-bootstrap';

export default function ExpandableRowComponent(props) {
    return (
        <Container style={{paddingLeft: '5%', paddingRight: '5%', fontSize: '12px', paddingTop: '1%'}}>
            <Table striped bordered>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>BJD</th>
                    <th>BJP</th>
                    <th>INC</th>
                    <th>Others</th>
                    <th>NOTA</th>
                </tr>
            </thead>
            <tbody>
            {props.data.data.map((yearwiseData) => {
                return (
                <tr>
                    <td>{yearwiseData.year}</td>
                    <td className={yearwiseData.bjd_position == 1 ? 'bg-success text-white' : ''}>{yearwiseData.bjd_votes + ' ( ' + yearwiseData.bjd_percent + '% )'}</td>
                    <td className={yearwiseData.bjp_position == 1 ? 'bg-success text-white' : ''}>{yearwiseData.bjp_votes + ' ( ' + yearwiseData.bjp_percent + '% )'}</td>
                    <td className={yearwiseData.inc_position == 1 ? 'bg-success text-white' : ''}>{yearwiseData.inc_votes + ' ( ' + yearwiseData.inc_percent + '% )'}</td>
                    <td className={yearwiseData.others_position == 1 ? 'bg-success text-white' : ''}>{yearwiseData.others_votes + ' ( ' + yearwiseData.others_percent + '% )'}</td>
                    <td className={yearwiseData.nota_position == 1 ? 'bg-success text-white' : ''}>{yearwiseData.nota_votes + ' ( ' + yearwiseData.nota_percent + '% )'}</td>
                </tr>
            );})}
            </tbody>
        </Table>
        </Container>
    );
}