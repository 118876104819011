import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

function ReportCard(props) {
  return (
    <Card style={{ width: '18rem', minHeight: '200px' }}>
      {/* <Card.Img variant="top" src={props.img} /> */}
      <Card.Body>
        <Card.Title style={{minHeight: '80px'}}>{props.title}</Card.Title>
        <Card.Text>
          Click here to see the report
        </Card.Text>
        <Link className="btn btn-primary" to={props.target}>Open Report</Link>
      </Card.Body>
    </Card>
  );
}

export default ReportCard;